@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@200;300;400;500;600;700;800&display=swap');

:host {
    display: block;
    background: #fff;
    border-radius: 8px;
    padding: 8px 16px;
  }
:root {
    --colorredlight: #FCF6F6;
    --colorPurple: #9b1fe8;
    --colorRed: #AC1D24;
    --bodyColor: #fff;
    --colorGrey: #626161;
    --colordkGrey: #2B2B2B;
    --colorDanger: #ff0000;
    --colorBlack: #000000;
    --graybg:#FBFBFB;
    --greenbtn:#4FC577;
    --btngray:#B4B4B4;
}

body {
    font-family: 'Plus Jakarta Sans', sans-serif;
    color: #626161;
    background-color: var(--bodyColor);
    line-height: 28px;
}
.gray_btn {
    background-color: var(--btngray) !important;
    color: var(--bodyColor) !important;
    border-radius: 67px !important;
    font-size: 18px !important;
    border: 1px solid #ffffff61 !important;
    font-weight: 600;
}

.red_btn {
    background-color: var(--colorRed) !important;
    color: var(--bodyColor) !important;
    border-radius: 67px !important;
    font-size: 18px !important;
    border: 1px solid #ffffff61 !important;
    font-weight: 600;
}
.green_btn {
    background-color: var(--greenbtn) !important;
    color: var(--bodyColor) !important;
    border-radius: 67px !important;
    font-size: 18px !important;
    font-weight: 600;
}
.btn.lightred_btn {
    background-color: var(--colorredlight)!important;
    border-radius: 67px!important;
    font-size: 16px!important;
}
.cancel_btn{
    color: var(--colordkGrey) !important;
    border-radius: 67px !important;
    font-size: 18px !important;
    border: 1px solid #626161 !important;
}
.btn_width{width:198px; height:57px}

.textgray{color: var(--colorGrey);}

.textblack{color: var(--colorBlack);}

// .textred{color: var(--colorRed); cursor: pointer;}

.textred{color: var(--colorRed);}

.fw300{font-weight: 300;}

.fw500{font-weight: 500;}

.fw600{font-weight: 600;}

.fw800{font-weight: 800;}

.fs12{font-size: 12px;}

.fs18{font-size: 18px;}

.fs14{font-size:14px;}

.fs16{font-size:16px;}

.fs20{font-size:20px;}

.radius10{border-radius:10px !important;}

.radius20{border-radius:20px !important;}

.hgt53{height: 53px;}

.borderbottom{border-bottom:1px solid #D9D9D9;}

.form-control {
    width: 100%;
    height: 60px;
    font-size: 16px;
    background-color: transparent;
    color:var(--colorBlack);
    border-radius:50px;
    border: none;
    border:1px solid #D2D2D2;
    padding:0px 24px;
}

select{
    width: 100%;
    height: 60px;
    font-size: 16px;
    background-color: transparent;
    color:#575757 !important;
    border-radius:50px;
    border: none;
    border:1px solid #D2D2D2;
    padding:0px 30px;
   }

.form-select{
    width: 100%;
    height: 60px;
    font-size: 16px;
    background-color: transparent;
    color:#575757 !important;
    border-radius:50px;
    border: none;
    border:1px solid #D2D2D2;
    padding:0px 30px;
}  
.modal-dialog .modal-content{border-radius: 17px;}
:focus{box-shadow:inherit !important}
// Users Testimonials css///
.owl-carousel{margin-top:55px;}
.shadow-effect {
    // background: #fff;
    padding: 20px;
    border-radius: 4px;
    text-align: center;
    // box-shadow: 0 19px 38px rgba(0,0,0,0.10), 0 15px 12px rgba(0,0,0,0.02);
}
#customers-testimonials .shadow-effect p {
    font-family: inherit;
    font-size: 17px;
    line-height: 1.5;
    margin: 0 0 17px 0;
    font-weight: 300;
}
.testimonial-name {
    margin: -17px auto 0;
    display: table;
    width: auto;
    background: #3190E7;
    padding: 9px 35px;
    border-radius: 12px;
    text-align: center;
    color:var(--bodyColor);
    box-shadow: 0 9px 18px rgba(0,0,0,0.12), 0 5px 7px rgba(0,0,0,0.05);
}
#customers-testimonials .item {
    text-align: center;
    padding: 0;
    margin-bottom:80px;
    // opacity: .2;
    // -webkit-transform: scale3d(0.8, 0.8, 1);
    // transform: scale3d(0.8, 0.8, 1);

    // -webkit-transition: all 0.3s ease-in-out;
    // -moz-transition: all 0.3s ease-in-out;
    // transition: all 0.3s ease-in-out;
}
#customers-testimonials .owl-item.active.center .item {
    opacity: 1;
    transform: scale3d(1, 1, 1);
    box-shadow: 0 6px 34px rgb(133 126 126 / 10%), 0 3px 15px rgba(0, 0, 0, 0.02);
    border-radius: 10px;
}

.owl-carousel .owl-item img {
    transform-style: preserve-3d;
    max-width: 97px;
    margin: 0 auto 17px;
    border-radius: 100px;
}
#customers-testimonials.owl-carousel .owl-dots .owl-dot.active span,
#customers-testimonials.owl-carousel .owl-dots .owl-dot:hover span {
    background: #DC571F;
    transform: translate3d(0px, -75%, 0px) scale(0.7);
  
}
#customers-testimonials.owl-carousel .owl-dots{
display: inline-block;
width: 100%;
text-align: center;
margin-top: 17px;
}
#customers-testimonials.owl-carousel .owl-dots .owl-dot{
display: inline-block;
}
#customers-testimonials.owl-carousel .owl-dots .owl-dot span {
    background: #FFE2E2;
    display: inline-block;
    height: 45px;
    margin: 0 0px 0px;
    transform:translate3d(0px, -74%, 0px) scale(0.3);
    transform-origin: 50% 50% 0;
    transition: all 250ms ease-out 0s;
    width: 45px;
    border-radius: 100px;
}

.owl-nav {
    position: absolute;
    top: -170px;
    right: 10px;
    width: fit-content;
    display: flex;
}
.owl-carousel .owl-prev{
    background: url('../src/assets/images/left_arrow.svg') no-repeat;
    left: -33px;
    color: #fff;
    width: 48px;
    height: 48px;
    margin-right: 10px;
    text-indent: 54px;
}
.owl-carousel .owl-next{
    background: url('../src/assets/images/right_arrow.svg') no-repeat;
    left: -33px;
    color:var(--bodyColor);
    width: 48px;
    height: 48px;
    text-indent: 54px;
}

// input css///
.login_wrap {
    textarea{border-radius:14px !important;  height: 80px !important; font-size: 16px; color:#575757 !important;}
   select{
    width: 100%;
    height: 60px;
    font-size: 16px;
    background-color: transparent;
    color:#575757 !important;
    border-radius:50px;
    border: none;
    border:1px solid #D2D2D2;
    padding:0px 30px;
   }
        .form-control {
                width: 100%;
                height: 60px;
                font-size: 16px;
                background-color: transparent;
                color:#000000;
                border-radius:50px;
                border: none;
                border:1px solid #D2D2D2;
                padding:0px 30px;
            }
            button {
                font-size: 18px;
                background-color:#AC1D24 !important;
                border: 0px;
                border-radius: 100px;
                width: 100%;
                height: 60px;
                font-weight: 600;
                opacity: 1 !important;
               
            } 
            button:hover{background-color: #a1484d;} 
            button:focus{background-color: #a1484d;}   
            .or_block{
                font-size: 20px;
                color:#626161;
                position: relative;
            }
            .or_block::before{
                        content: "";
                        width: 180px;
                        height: 1px;
                        background-color: #D9D9D9;
                        position: absolute;
                        top: 65px;
                        left: 56px;
            }
          .or_block::after {
                content: "";
                width: 180px;
                height: 1px;
                background-color: #D9D9D9;
                position: absolute;
                top: 65px;
                right: 54px;
            }
            .social_link{
                width:100%;
                height:60px;
                border:1px solid #D2D2D2;
                font-size: 16px;
                color:#626161;
                border-radius: 100px;
                padding: 12px; 
                text-align: center;
                cursor: pointer;
                margin-bottom: 30px;
            }

    }

    .page-content-wrapper .content-wrap {
        height: calc(100vh - 146px);
        position: relative;
    }
    .iti.iti--allow-dropdown {
        width: 100%;
    }
    .ng-otp-input-wrapper .otp-input{
        margin-right:10px !important;
        width: 60px !important;
        height: 60px !important;
    }
///modal css//
.mat-dialog-container {
    border-radius: 20px !important;
}
.mat-dialog-container {
    box-shadow: inherit !important;
}
.modal-content{border: 0px !important;}

///date css//
.mat-form-field-label-wrapper {
    top: 2px;
    padding-top: 1.84375em;
    left: 26px !important;
}
.mat-form-field-appearance-legacy .mat-form-field-infix {
    padding: 0;
}
.mat-form-field-infix{border-top:0px !important}
.mat-form-field {
    font-weight: 500 !important;
    font-family:"Plus Jakarta Sans", sans-serif !important; 
}
.mat-form-field-appearance-legacy .mat-form-field-underline{ display: none;}
.mat-datepicker-toggle{
    top: 9px !important;
    right:0px !important;
    position:absolute;
}
.mat-form-field-appearance-legacy .mat-form-field-wrapper {
    padding-bottom: 0 !important;
}
input.mat-input-element {
    padding-left: 29px;
    max-width: 96%;
}   
//rating css///
ngb-rating {
    color: #FFC107;
    font-size: 20px;
  }
  
  .star {
    position: relative;
    display: inline-block;
    color: #d3d3d3;
  }
  .full {
    color: #FFC107;
  }
  .half {
    position: absolute;
    display: inline-block;
    overflow: hidden;
    color: #d5a92d;
  }
  .rating-itmbox .content-sec{
    flex: 1;
  }
  //tab css///
  .mat-tab-header {
    display: flex;
    overflow: hidden;
    position: relative;
    flex-shrink: 0;
    background-color: #fff;
    border-radius:10px;
    width: 360px;
    margin-left: auto;
    margin-top: 23px;
    margin-right: 10px;

}
.mat-tab-header{margin-bottom: 30px;}

.mat-tab-label{
    font-size:16px;
    font-weight: 500;
    padding: 0 10px !important;
    min-width:178px !important;
}
.mat-tab-group {
    font-family:"Plus Jakarta Sans", sans-serif !important; 
}
.mat-tab-label, .mat-tab-link {
    font-family:"Plus Jakarta Sans", sans-serif !important; 

}
.mat-tab-label-active {
    border-bottom: 0px;
    background-color: #AC1D24 !important;
    color: #fff !important;
}
.mat-tab-group.mat-primary .mat-ink-bar, .mat-tab-nav-bar.mat-primary .mat-ink-bar {
    background-color: transparent;
}
.mat-tab-label, .mat-tab-link {
    color:var(--colorGrey);
}
.mat-tab-label{opacity: 1 !important;}
.error_message{
    color: var(--colorDanger);
}

.c-pointer{
    cursor: pointer;
}

.googlebtninner {
    position: absolute;
    opacity: 0.000001;
    // visibility: hidden;
    left: 0;
    top: 0;
    z-index: 9;
    height: 100%;
    width: 100%;

    // iframe{
    //     opacity: 0;
    // }
  }
//custom scroll///
// .container-fluid .content-wrap {
//     height: calc(100vh - 146px);
//     position: relative;
// }
// .customScroll {
//     height: 100%;
//     width: 100%;
//     overflow: hidden;
//     position: absolute;
// }

#buttonDiv iframe {
    width: 100% !important;
    height: 66px !important;
}
#buttonDiv .nsm7Bb-HzV7m-LgbsSe {
    border: 1px solid #dadce0;
    color: #838383;
    font-size: 18px;
    height: 57px;
    padding: 0 142px;
    position: relative;
    text-align: center;
    vertical-align: middle;
    white-space: nowrap;
    width: auto;
    border-radius: 100px;
}
div#container {
    width: 98% !important;
}
.mat-dialog-container{
    overflow:inherit !important;
}
// .cdk-overlay-pane{width:39% !important;}
.setting_block .mat-tab-header {
    width: auto;
    margin-left: inherit;
    margin-top: 0;
    margin-right: 0;
    background-color: transparent;
    border-bottom: 0;
    border-radius: 0;
}
.cur{cursor: pointer;}
// #buttonDiv iframe {
//     position: relative;
//     margin: -34px auto !important;
//     opacity: 0;
// }
#buttonDiv {
    position: absolute;
    opacity: 0;
    top: 0;
    height: 100%;
    width: 100%;
}
// .login_wrap {
//     width: 55%;
//     margin: auto;
//     /* display: flex; */
//     /* align-items: center; */
//     /* flex-direction: column; */
//     /* justify-content: center; */
//     /* height: 90vh; */
//     padding-top: 9rem;
  
// }
.login_wrap {
    width: 55%;
    margin: auto;
    padding-top: 0;
    height: 97vh;
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    justify-content: center;

}
.logo_block {
    position: absolute;
    top: 40%;
    right: 0px;
    left: -52px;
    margin: auto;
    width: 173px;
}
.left_img {
    background: url('assets/images/left_login_img.png') no-repeat;
    background-size: cover;
    height: 95vh;
    margin: 22px;
    border-radius: 20px;
    position: relative;
}

.active-nav .navbar.top-navbar{

    margin-left: 300px;
}
.active-nav .side-navbar{
    margin-left: 0;
}
.active-cont {
    margin-left: 0px;
    transition: 0.5s;
}

.active-nav .active-cont {
    margin-left: 300px;
}
.toogle-button {
    display: none;
  }
#closeSidebar {display: none;
  }
.top-header{ top: 0;  width: 100%; height: 84px; 
    border-radius: 22px; margin-top: 3.4rem;}

@media  (max-width:480px){
    .report_detail_bg img {
        width: 100%;
    }
    .mat-tab-header{width: 100%;}
    .header-right{
        padding-top: 10px;
    }
    .top-header{margin-top:1.4rem}
    .serach_filter{margin-top: 10px;}
    .form-control{padding: 0px 15px;}
    .top-header{height: auto;}
    #closeSidebar {
        position: absolute;
        top: 10px;
        right: 10px;
        display: block;
    }
    .login_wrap {
        width:95%;
        margin: auto;
        padding-top:0rem;
        height: auto;
    }
    .logo_block{
        position: absolute;
        top: 70px;
        right: 0px;
        left: 0;
        margin: auto;
        width: 100%;
        text-align: center;
    }
    .left_img{
        height: 70vh;
        margin: 22px;
    }
    .toogle-button {
        margin-top: 24px;
        display: block;
      }
}
@media  (min-width:1300px) and (max-width:1400px){
    .file_report_modal .form-control{
      width: 100% !important;
      height:45px!important;
      font-size: 12px !important;
  }
  .file_report_modal .form-select{height:45px !important;      font-size: 12px !important;}
  .file_report_modal .input_date_block input{height:45px !important; font-size:12px;}
  textarea.form-control{
    min-height: 100px !important;
    padding: 15px 28px;
}
.mat-form-field-appearance-legacy .mat-form-field-label {
    top: 1.28125em;
    font-size: 12px;
}
.btn_width {
    width: 194px;
    height: 46px;
    font-size: 14px !important;
}
#sidebar-wrapper{
    width: 244px !important;
}
.page-content-wrapper{
    margin-left: 18rem !important;
}

.login_wrap .form-control {
    height:45px;
    font-size: 12px;
}
.form-label {
    font-size: 14px !important;
    margin-bottom: 0;
}
.login_wrap h3 {
    font-size: 20px;
}
.login_wrap p {
    font-size: 14px !important;
}
mat-icon.mat-icon.notranslate.mttwo.material-icons.mat-icon-no-color {
    top: 38px;
}
.login_wrap {
    padding-top: 1rem !important;
}
.login_wrap button {
    font-size: 15px;
    background-color: #AC1D24 !important;
    border: 0px;
    border-radius: 100px;
    width: 100%;
    height: 51px;
    font-weight: 600;
    opacity: 1 !important;
}
.login_wrap .or_block::before {
    content: "";
    width: 130px;
    height: 1px;
    background-color: #D9D9D9;
    position: absolute;
    top: 32px;
    left: 31px;
}
.login_wrap .or_block::after {
    content: "";
    width: 130px;
    height: 1px;
    background-color: #D9D9D9;
    position: absolute;
    top: 32px;
    right: 29px;
}
.login_wrap .social_link {
    height: 50px;
    font-size: 13px;
}
.login_wrap{
    height: 100% !important;
}
// .login_wrap img {
//     width: 38% !important;
// }
.ng-otp-input-wrapper .otp-input {
    margin-right: 10px !important;
    width: 55px !important;
    height: 55px !important;
}
  }

